<mat-pseudo-checkbox *ngIf="multiple" class="mat-mdc-option-pseudo-checkbox"
    [state]="selected ? 'checked' : 'unchecked'" [disabled]="disabled"></mat-pseudo-checkbox>

<ng-content select="mat-icon"></ng-content>

<span class="mdc-list-item__primary-text" #text><ng-content></ng-content></span>

<!-- Render checkmark at the end for single-selection. -->
<mat-pseudo-checkbox *ngIf="!multiple && selected && !hideSingleSelectionIndicator"
    class="mat-mdc-option-pseudo-checkbox" state="checked" [disabled]="disabled"
    appearance="minimal"></mat-pseudo-checkbox>

<!-- See a11y notes inside optgroup.ts for context behind this element. -->
<span class="cdk-visually-hidden" *ngIf="group && group._inert">({{ group.label }})</span>

<div class="mat-mdc-option-ripple mat-mdc-focus-indicator" mat-ripple
     [matRippleTrigger]="_getHostElement()"
     [matRippleDisabled]="disabled || disableRipple">
</div>
